<template>
	<div class="ipstorage">
		<div class="storage center-noflex">
			<div style="font-size: 40px; font-weight: 900">IP服务</div>
			<div class="subtitle">专业服务机构共建平台生态链</div>
			<div class="Apply" @click="fuwuruzhu()">申请入驻</div>
		</div>
		<div class="tabbox" v-if="is_show">
			<div class="flex">
				<div>行业分类：</div>
				<div style="margin-right: 48px; margin-left: 40px" :class="{ selectTab_item: SelectTab == 0 }" @click="Selecttabs(0)">
					全部
				</div>
				<div class="flex" style="flex-wrap: wrap">
					<div v-for="(item, index) in tabList" :key="index" class="tabitem" :class="{ selectTab_item: SelectTab == index + 1 }" @click="Selecttabs(index + 1)">
						{{ item.name }}
					</div>
				</div>
			</div>
		</div>

		<div class="storage-content">
			<div class="storage-list center-noflex">
				<div class="storage-item align-center" v-for="(item, index) in storageList" :key="index" @click="Godetali(item)">
					<div class="item-img">
						<img :src="item.image" alt="" />
					</div>
					<div>
						<div class="item-title">{{ item.title }}</div>
						<div class="item-industry">行业：{{ item.trade }}</div>
						<div class="item-content flex">简介：<span v-html="item.remark"></span>
						</div>
					</div>
				</div>
			</div>
			<div class="storage-pagination">
				<el-pagination background layout="prev, pager, next" :total="total"></el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		
		async created() {
			var id=this.$route.query.id
			if(id==3){
				this.is_show=true;
			}else{
				this.is_show=false;
			}
			const {
				data
			} = await this.$api({
				c: "index",
				a: "sevice_index",
				cate_id: this.$route.query.id,
				trade_id: "0",
			});
			this.tabList = data.trade;
			this.storageList = data.arr;
			this.total = data.count;
		},
		data() {
			return {
				is_show:false,
				SelectTab: 0,
				total: "",
				tabList: [
					// {
					//   name: "研发服务",
					//   id: "3",
					// },
					// {
					//   name: "投资服务",
					//   id: "4",
					// },
					// {
					//   name: "代理服务",
					//   id: "5",
					// },
					// {
					//   name: "律师服务",
					//   id: "6",
					// },
				],
				storageList: [
					// {
					//   img: require("@/assets/test/test_Icon4.png"),
					//   title: "存鱼科技有限公司",
					//   industry: "动漫游戏",
					//   content:
					//     "照亮汉朝的一束光照亮汉朝的一束光照亮汉朝的一束光照亮汉朝的一束光照亮汉朝束照亮汉朝的一束光照亮汉朝的一",
					// },
					// {
					//   img: require("@/assets/test/test_Icon4.png"),
					//   title: "存鱼科技有限公司",
					//   industry: "动漫游戏",
					//   content:
					//     "照亮汉朝的一束光照亮汉朝的一束光照亮汉朝的一束光照亮汉朝的一束光照亮汉朝束照亮汉朝的一束光照亮汉朝的一",
					// },
					// {
					//   img: require("@/assets/test/test_Icon4.png"),
					//   title: "存鱼科技有限公司",
					//   industry: "动漫游戏",
					//   content:
					//     "照亮汉朝的一束光照亮汉朝的一束光照亮汉朝的一束光照亮汉朝的一束光照亮汉朝束照亮汉朝的一束光照亮汉朝的一",
					// },
					// {
					//   img: require("@/assets/test/test_Icon4.png"),
					//   title: "存鱼科技有限公司",
					//   industry: "动漫游戏",
					//   content:
					//     "照亮汉朝的一束光照亮汉朝的一束光照亮汉朝的一束光照亮汉朝的一束光照亮汉朝束照亮汉朝的一束光照亮汉朝的一",
					// },
				],
			};
		},
		methods: {
			Godetali(e) {
				this.$router.push({
					path: "/index/servicedetali",
					query: {
						id: e.id,
					},
				});
			},
			fuwuruzhu() {
				this.$router.push("/index/service_add");
			},
			async Selecttabs(e) {
				//console.log(this.$route.query.id + '^^^^' + e);
				var trade_id=0;
				if (e == 0) {
					trade_id = 0;
				} else {
					var j = Number(e) - 1;
					trade_id = this.tabList[j].id;
				}
				const {
					data
				} = await this.$api({
					c: "index",
					a: "sevice_index",
					cate_id: this.$route.query.id,
					trade_id: trade_id,
				});
				this.SelectTab = e;
				// 分类切换
				if (data) {
					this.storageList = data.arr;
					this.total = data.count;
				} else {
					this.storageList = [];
					this.total = 0;
				}

			},
		},
	};
</script>

<style lang="scss" scoped>
	.storage {
		height: 400px;
		background: url("../../assets/service/service.png");
		background-size: 100% 100%;
		color: #ffffff;

		.subtitle {
			font-weight: 100;
			margin: 30px 0px 15px;
		}

		.Apply {
			background: rgb(253, 71, 1);
			border-radius: 100px 100px 100px 0px;
			padding: 7px 16px;
		}
	}

	.storage-item {
		padding: 16px 0;
		border-bottom: 1px solid #dfdfdf;
		width: 100%;
		cursor: pointer;
	}

	.storage-content {
		padding: 8px;
	}

	.item-title {
		font-size: 20px;
		font-weight: bold;
	}

	.item-industry {
		margin: 20px 0px;
		color: rgb(199, 199, 199);
	}

	.item-content {
		color: rgb(199, 199, 199);
	}

	.item-img {
		margin-right: 20px;

		img {
			object-fit: cover;
			height: 100px;
			width: 200px;
		}
	}

	.storage-list {
		width: 900px;
		white-space: normal;
		margin: 0 auto;
	}

	.storage-pagination {
		display: flex;
		justify-content: center;
		margin: 37px 0px 57px;
	}

	.tabitem {
		margin-bottom: 5px;
		margin-right: 40px;
		cursor: pointer;
	}

	.selectTab_item {
		color: rgb(0, 118, 254);
	}

	.tabbox {
		display: flex;
		justify-content: center;
		padding: 40px 0;

		.flex {
			width: 900px;
		}
	}
</style>
